.footer {
  width: 70%;
  margin: 0 auto;
  margin-top: 15rem;

  .links {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
      margin-right: 30px;
    }
    h4 {
      font-size: 1.6rem;
      margin: 0;
      margin-right: 50px;
    }
  }
  .icon {
    height: 30px;
    width: 30px;
  }

  @media only screen and (max-width: 1150px) {
    width: 80%;
  }

  @media only screen and (max-width: 950px) {
    width: 90%;
  }

  &_hero {
    color: #000000dd;
    font-size: 2.5rem;
  }
  &_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    p,
    a {
      font-size: 2rem;
      color: #3e4551;
      margin-left: 2rem;
    }
  }
}
