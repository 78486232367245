html,
body {
  box-sizing: border-box;
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  font-family: Product Sans, sans-serif;
}

@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
}

.app {
  width: 90%;
  margin: 0 auto;
}

/* @font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans"), local("OpenSans"),
    url("./fonts/GoogleSans-Bold-v1.27.ttf") format("woff2");
} */
a:link,
a:visited {
  color: black;
  text-decoration: none;
}
